import React, { useState } from "react";

function Login({ saveName }: { saveName: (name: string) => void }) {
  const [name, setName] = useState("");
  return (
    <div className="section">
      <div className="container">
        <div className="card">
          <div className="card-content">
            <h1 className="is-size-3 has-text-weight-bold">Login</h1>
            <form
              onSubmit={(ev) => {
                ev.preventDefault();
                if (name.length > 0) saveName(name);
              }}
            >
              <div className="field">
                <label className="label">Name</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    value={name}
                    onChange={(ev) => setName(ev.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <input
                    type="submit"
                    value="Next"
                    className="button is-primary"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
