import { Expr } from "faunadb";

export enum CurrencyType {
  CHF = "CHF",
  EUR = "EUR",
}

export interface ItemType {
  name: string;
  amount: number;
  currency: CurrencyType;
}

export enum AccountType {
  ASSETS = "assets",
  LIABILITIES = "liabilities",
}

export type AccountsType = Record<AccountType, ItemType[]>;

export interface FaunaQueryResult {
  data: {
    name: string;
    items: AccountsType;
  };
  ts: number;
  ref: Expr;
}

export interface FaunaFindResponse {
  data: FaunaQueryResult[];
}
