import React, { useState } from "react";
import Accounts from "./components/Accounts";
import Login from "./components/Login";

import { QueryClient, QueryClientProvider } from "react-query";

// Create a client
const queryClient = new QueryClient();

function App() {
  const [name, setName] = useState(window.location.pathname.slice(1));
  return (
    <QueryClientProvider client={queryClient}>
      {name ? (
        <Accounts name={name} />
      ) : (
        <Login
          saveName={(n) => {
            setName(n);
            window.history.replaceState({}, "", "/" + n);
          }}
        />
      )}
    </QueryClientProvider>
  );
}

export default App;
