import { CurrencyType } from "../types";

export default function formatMoney(amount: number, currency: CurrencyType) {
  const formatter = new Intl.NumberFormat("fr-CH", {
    style: "currency",
    currency,
    currencyDisplay: "code",
  });
  return formatter.format(amount);
}
