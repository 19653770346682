import React, { useState } from "react";
import axios, { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

import EditSection from "./NewItem";
import { CurrencyType, AccountType, ItemType, AccountsType } from "../types";
import formatMoney from "../utils/formatMoney";

const getAvailableMoney = (data: AccountsType) => {
  const assetsTotal = data.assets.reduce((acc, item) => {
    return acc + item.amount;
  }, 0);
  const liabilitiesTotal = data.liabilities.reduce((acc, item) => {
    return acc + item.amount;
  }, 0);

  return assetsTotal - liabilitiesTotal;
};

function Accounts({ name }: { name: string }) {
  const [show, setShow] = useState(false);
  // const [data, setData] = useState(initData);
  const [editData, setEditData] = useState<{
    account: AccountType | undefined;
    item?: ItemType;
  }>({
    account: undefined,
  });

  const queryClient = useQueryClient();

  const { isLoading, error, data } = useQuery<AccountsType, AxiosError>(
    "accounts",
    () => {
      return axios
        .get("/.netlify/functions/getItems", {
          params: {
            name,
          },
        })
        .then((res) => res.data);
    }
  );

  const mutation = useMutation(
    (newData: AccountsType) =>
      axios
        .get("/.netlify/functions/saveItems", {
          params: {
            name,
            items: JSON.stringify(newData),
          },
        })
        .then((res) => res.data),
    {
      onSuccess: (updatedData: AccountsType) => {
        queryClient.setQueryData("accounts", updatedData);
      },
    }
  );

  if (isLoading) {
    return (
      <div
        className="is-flex is-justify-content-center is-align-items-center"
        style={{ height: "100vh", width: "100%" }}
      >
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  if (error || !data) {
    return <div>An error occured</div>;
  }

  const onSave = async (account: AccountType, item: ItemType) => {
    const newAccountData = [
      ...data[account].filter((it) => it.name !== item.name),
      item,
    ];

    const newData = {
      ...data,
      [account]: newAccountData,
    };

    mutation.mutate(newData);
  };

  const onDelete = async (account: AccountType, itemName: string) => {
    const confirmDelete = confirm(
      "Are you sure that you want to delete this entry?"
    );

    if (confirmDelete) {
      const newAccountData = data[account].filter((it) => it.name !== itemName);

      const newData = { ...data, [account]: newAccountData };

      mutation.mutate(newData);
    }
  };

  const available = getAvailableMoney(data);

  return (
    <>
      <EditSection
        show={show}
        setShow={setShow}
        onSave={onSave}
        editData={editData}
      />
      <div className="section" style={{ marginBottom: "100px" }}>
        <div className="container">
          <div className="is-flex is-justify-content-space-between pb-2">
            <h2 className="is-size-4 has-text-weight-bold">Assets</h2>
            <button
              className="button is-primary is-rounded"
              onClick={() => {
                setEditData({ account: AccountType.ASSETS });
                setShow(true);
              }}
            >
              +
            </button>
          </div>
          {data.assets.map((it) => (
            <div
              key={it.name}
              className="box is-flex is-justify-content-space-between is-clickable"
              onClick={() => {
                setEditData({ account: AccountType.ASSETS, item: it });
                setShow(true);
              }}
            >
              <p>{it.name}</p>
              <div className="is-flex is-align-items-center">
                <p className="has-text-weight-bold">
                  {formatMoney(it.amount, it.currency)}
                </p>
                <button
                  className="delete is-medium ml-3"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    onDelete(AccountType.ASSETS, it.name);
                  }}
                ></button>
              </div>
            </div>
          ))}
          <div className="is-flex is-justify-content-space-between pb-2">
            <h2 className="is-size-4 has-text-weight-bold">Liabilities</h2>
            <button
              className="button is-primary is-rounded"
              onClick={() => {
                setEditData({ account: AccountType.LIABILITIES });
                setShow(true);
              }}
            >
              +
            </button>
          </div>
          {data.liabilities.map((it) => (
            <div
              key={it.name}
              className="box is-flex is-justify-content-space-between is-clickable"
              onClick={() => {
                setEditData({ account: AccountType.LIABILITIES, item: it });
                setShow(true);
              }}
            >
              <p>{it.name}</p>
              <div className="is-flex is-align-items-center">
                <p className="has-text-weight-bold">
                  {formatMoney(it.amount, it.currency)}
                </p>
                <button
                  className="delete is-medium ml-3"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    onDelete(AccountType.LIABILITIES, it.name);
                  }}
                ></button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className="section"
        style={{ position: "fixed", bottom: 0, right: 0 }}
      >
        <div className="box">
          <p className="has-text-weight-bold">
            Available: {formatMoney(available, CurrencyType.CHF)}
          </p>
        </div>
      </div>
    </>
  );
}

export default Accounts;
