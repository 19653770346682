import React, { useEffect, useState } from "react";
import { AccountType, CurrencyType, ItemType } from "../types";
import Modal from "./Modal";

const emptyData = { name: "", amount: "", currency: CurrencyType.CHF };

const accountTypeToString = {
  [AccountType.ASSETS]: "asset",
  [AccountType.LIABILITIES]: "liability",
};

export default function EditItem({
  show,
  setShow,
  onSave,
  editData,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: (account: AccountType, item: ItemType) => void;
  editData: {
    account: AccountType | undefined;
    item?: ItemType;
  };
}) {
  const [data, setData] = useState(emptyData);

  useEffect(() => {
    setData(
      editData.item
        ? { ...editData.item, amount: editData.item.amount.toString() }
        : emptyData
    );
  }, [editData]);

  if (!editData.account) {
    return null;
  }

  const setDataName = (key: string, value: string) => {
    setData({ ...data, [key]: value });
  };

  return (
    <Modal
      show={show}
      setShow={setShow}
      title={
        (editData.item ? "Edit " : "New ") +
        accountTypeToString[editData.account]
      }
      onSave={() => {
        if (editData.account && data.name && data.amount) {
          setData(emptyData);
          onSave(editData.account, {
            ...data,
            amount: parseFloat(data.amount),
          });
          setShow(false);
        }
      }}
    >
      <>
        <div className="field">
          <label className="label">Name</label>
          <div className="control">
            <input
              className="input"
              type="text"
              value={data.name}
              onChange={(ev) => setDataName("name", ev.target.value)}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Amount</label>
          <div className="control">
            <input
              className="input"
              type="number"
              value={data.amount}
              onChange={(ev) => setDataName("amount", ev.target.value)}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Currency</label>
          <div className="control">
            <div className="select">
              <select
                value={data.currency}
                onChange={(ev) => setDataName("currency", ev.target.value)}
              >
                {Object.values(CurrencyType).map((currency) => (
                  <option key={currency}>{currency}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
}
