import React from "react";

export default function Modal({
  show,
  setShow,
  title,
  children,
  onSave,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: () => void;
  title: string;
  children: React.ReactNode;
}) {
  return (
    <div className={"modal " + (show ? "is-active" : "")}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => setShow(false)}
          ></button>
        </header>
        <section className="modal-card-body">{children}</section>
        <footer className="modal-card-foot">
          <button className="button is-success" onClick={onSave}>
            Save changes
          </button>
          <button className="button" onClick={() => setShow(false)}>
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
}
